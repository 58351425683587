import React, {forwardRef, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";

const listOfWords = "something – noget\n" +
  "they/the – de\n" +
  "difficult – svært\n" +
  "to – til\n" +
  "time – tid\n" +
  "good – god\n" +
  "these – disse\n" +
  "which – hvilke\n" +
  "many – mange\n" +
  "bullying – mobning\n" +
  "from – fra\n" +
  "isolated – isolerede\n" +
  "tool – værktøj\n" +
  "shown – vist\n" +
  "have – har\n" +
  "on/in – på\n" +
  "grows – vokser\n" +
  "updates – opdateringer\n" +
  "mobile phones – mobiltelefoner\n" +
  "days – dage\n" +
  "hold – holde\n" +
  "or – eller\n" +
  "self – selv\n" +
  "finally – endelig\n" +
  "shall – skal\n" +
  "talk/tell – tale\n" +
  "them – dem\n" +
  "to fall – falde\n" +
  "people – mennesker\n" +
  "how – hvordan\n" +
  "do/make – gøre\n" +
  "others – andre\n" +
  "with – med\n" +
  "big – stor\n" +
  "it – det\n" +
  "best – bedste\n" +
  "school – skole\n" +
  "idea – idé\n" +
  "aware – opmærksom\n" +
  "in order to – for at\n" +
  "despite – trods\n" +
  "stories – historier\n" +
  "where – hvor\n" +
  "limit – begrænse\n" +
  "as/like – som\n" +
  "young people – unge\n" +
  "send – sende\n" +
  "is/are – er\n" +
  "to love – elske\n" +
  "about – om\n" +
  "tell – fortælle\n" +
  "cyberbullying – cybermobning\n" +
  "change – ændre\n" +
  "we – vi\n" +
  "belief – tro\n" +
  "news – nyheder\n" +
  "avoid – undgå\n" +
  "one (person) – man\n" +
  "more/several – flere\n" +
  "reality – virkeligheden\n" +
  "disadvantages – ulemper\n" +
  "possible – mulig\n" +
  "of – af\n" +
  "websites – websteder\n" +
  "feel – føler\n" +
  "takes – tager\n" +
  "personal – personlig\n" +
  "social – sociale\n" +
  "same – samme\n" +
  "day – dag\n" +
  "feelings – følelser\n" +
  "family – familie\n" +
  "to use – bruge\n" +
  "low – lavt\n" +
  "message – besked\n" +
  "available – tilgængelig\n" +
  "world – verden\n" +
  "this – dette\n" +
  "by/at – ved\n" +
  "addiction – afhængighed\n" +
  "can – kan\n" +
  "experiences – oplevelser\n" +
  "one’s – ens\n" +
  "access – adgang\n" +
  "share – dele\n" +
  "express – udtrykke\n" +
  "and – og\n" +
  "place – sted\n" +
  "society – samfund\n" +
  "but – men\n" +
  "articles – artikler\n" +
  "now – nu\n" +
  "my/mine – mine\n" +
  "self/themselves – sig\n" +
  "long – længe\n" +
  "quickly – hurtigt\n" +
  "biggest – største\n" +
  "healthy – sund\n" +
  "in/into – i\n" +
  "consequences – konsekvenser\n" +
  "debates – debatter\n" +
  "make/do – lave\n" +
  "see – se\n" +
  "self-confidence – selvtillid\n" +
  "life – liv\n" +
  "some – nogen\n" +
  "easy – nemt\n" +
  "whole – hele\n" +
  "often – ofte\n" +
  "activities – aktiviteter\n" +
  "his/her/its – sin\n" +
  "he – han\n" +
  "most – fleste\n" +
  "against – mod\n" +
  "new – nyt\n" +
  "get/few – få\n" +
  "opportunities – muligheder\n" +
  "conscious – bevidst\n" +
  "be – være\n" +
  "a/an – et\n" +
  "follow – følge\n" +
  "border/limit – grænse\n" +
  "important – vigtigt\n" +
  "increase – øge\n" +
  "anonymity – anonymitet\n" +
  "enough – nok\n" +
  "to – at\n" +
  "therefore – derfor\n" +
  "year – år\n" +
  "way (of doing something) – måde\n" +
  "powerful – kraftfuldt\n" +
  "daily – daglig\n" +
  "me – mig\n" +
  "knowledge – viden\n" +
  "relax – slappe\n" +
  "a/an/one – en\n" +
  "what – hvad\n" +
  "move – flytter\n" +
  "find – finde\n" +
  "when – når\n" +
  "remember – huske\n" +
  "advantage – fordel\n" +
  "since – siden\n" +
  "will – vil\n" +
  "one another – hinanden\n" +
  "powerless – magtesløse\n" +
  "pictures – billeder\n" +
  "town/city – by\n" +
  "globalized – globaliseret\n" +
  "homework – lektier\n" +
  "friends – venner\n" +
  "for – for\n" +
  "the – den\n" +
  "face – ansigt\n" +
  "out – ud\n" +
  "problems – problemer\n" +
  "people – folk";


function formatText(input) {
  // Split the input by newline to get each line
  const lines = input.split('\n');

  // Map each line into the desired HTML format
  const formattedLines = lines.map(line => {
    // Split each line by spaces to get the two words
    const [word1, word2] = line.split('-');

    // Return the formatted HTML string for each line
    return (<p><strong>{word2}</strong> - {word1}</p>);
  });

  return formattedLines;
}

const FormattedText = forwardRef(({text, reverted = false}, ref) => {
  // Split and format the text
  const formatText = (input) => {
    return input.split('\n').map((line, index) => {
      const [word1, word2] = line.split('–');
      return (
        <span key={index}><strong>{reverted ? word1 : word2}</strong> - {reverted ? word2 : word1}<br/></span>
      );
    });
  };

  return <div ref={ref} id={(reverted ? "englishToDanish" : "danishToEnglish") + "Content"}>{formatText(text)}</div>;
});


const DanishWordsPage = () => {
  const {t} = useTranslation();

  const [isCopied, setIsCopied] = useState(false);
  const textRefDanishEnglish = useRef(null);

  const [isEnglishDanishCopied, setIsEnglishDanishCopied] = useState(false);
  const textRefEnglishDanish = useRef(null);

  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    lineHeight: '1.6',
    paddingTop: '100px',
    paddingBottom: '100px'
  };

  const buttonStyle = {
    marginBottom: '12px'
  }

  const copyTextDanishEnglish = async () => {
    if (textRefDanishEnglish.current) {
      const textToCopy = textRefDanishEnglish.current.innerText; // Access the innerText of the element
      try {
        await navigator.clipboard.writeText(textToCopy);
        setIsCopied(true);

        // Reset "Copied" message after a delay
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    }
  };

  const copyTextEnglishDanish = async () => {
    if (textRefEnglishDanish.current) {
      const textToCopy = textRefEnglishDanish.current.innerText; // Access the innerText of the element
      try {
        await navigator.clipboard.writeText(textToCopy);
        setIsEnglishDanishCopied(true);

        // Reset "Copied" message after a delay
        setTimeout(() => {
          setIsEnglishDanishCopied(false);
        }, 2000);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    }
  };

  return (
    <div style={containerStyle}>
      <h1 style={{paddingBottom: 20}}>{t('danishWords.title')}</h1>
      <h3>Danish to English (good for passive text understanding)</h3>
      <p>{t('danishWords.copyAndPasteIntoApp')}</p>
      <button style={buttonStyle} onClick={copyTextDanishEnglish}>Copy</button>
      {isCopied && <span style={{paddingLeft: '8px'}}>Copied!</span>}
      <FormattedText text={listOfWords} ref={textRefDanishEnglish}/>
      <h3 style={{paddingTop: "16px"}}>English to Danish (good for learning to speak the language)</h3>
      <p>{t('danishWords.copyAndPasteIntoApp')}</p>
      <button style={buttonStyle} onClick={copyTextEnglishDanish}>Copy</button>
      {isEnglishDanishCopied && <span style={{paddingLeft: '8px'}}>Copied!</span>}
      <FormattedText text={listOfWords} ref={textRefEnglishDanish} reverted={true}/>
    </div>
  );
};

export default DanishWordsPage;